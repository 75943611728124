<script setup lang="ts">
  import contactMarketoForm from "../form-element/contact-form-base.vue";
  import { type FormWidgetData } from "../../../widgetDataTs.js";
  import { useFormServices } from "../../composables/use-form-services.js";
  import { computed } from "vue";
  import type { ContactFormSettingsKey } from "../../form-settings/form-experience/form-settings-keys.js";
  defineProps<{
    formSettingsKey: ContactFormSettingsKey,
    widgetData: FormWidgetData<"ContactForm">,
    customData?: Record<string, any>,
  }>();
  const { FormState } = useFormServices({ callerLabel: "ppc-contact" });
  const showUnlockedView = computed(() => {
    return FormState.value === "RegularFormComplete";
  });
</script>

<template>
  <template v-if="!showUnlockedView">
    <contactMarketoForm :form-settings-key="formSettingsKey" :widget-data="widgetData"  :custom-data="customData"/>
  </template>
  <template v-else>
    <div class="ppc-contact-success">
        <div class="form-complete">
          <div class="response">
            <h2 class="primary-heading">Thank you for your interest!</h2>
            <p class="supporting-copy">One of our team members will be in touch soon.</p>
          </div>
        </div>
      </div>
  </template>
</template>